<template>
  <div class="grid-x grid-margin-x">
    <div class="cell small-12 medium-6">
      <hcc-input
        :placeholder="$t('shop.product.name')"
        v-model="newProduct.nombre"
        @blur="$v.newProduct.nombre.$touch()"
        :error="$v.newProduct.nombre.$error"
        :errorMessage="
        $t('shop.errors.required', { field: $t('shop.product.name') })
        "
        :label="$t('shop.product.name')"
        ref="hccInput"
      />
      <hcc-select
        :label="$t('shop.product.category')"
        custom-class="select"
        v-model="newProduct.categoria"
        :placeholder="$t('shop.product.select-option')"
        :options="categories"
        :error="$v.newProduct.categoria.$error"
        :errorMessage="
        $t('shop.errors.required', { field: $t('shop.product.category') })"
        option-label="value"
        track-by="id"
      />
      <hcc-money
        :placeholder="$t('shop.product.price')"
        v-model="newProduct.precio"
        @blur="$v.newProduct.precio.$touch()"
        :error="$v.newProduct.precio.$error"
        :errorMessage="
        $t('shop.errors.required', { field: $t('shop.product.price') })"
        :label="$t('shop.product.price')"
      />
      <hcc-money
        :placeholder="$t('shop.product.purchase_price')"
        v-model="newProduct.precioCompra"
        @blur="$v.newProduct.precioCompra.$touch()"
        :error="$v.newProduct.precioCompra.$error"
        :errorMessage="
        $t('shop.errors.required', { field: $t('shop.product.purchase_price')})"
        :label="$t('shop.product.purchase_price')"
      />
      <hcc-input
        v-if="isProduct"
        type="number"
        :placeholder="$t('shop.product.stock')"
        v-model="newProduct.stock"
        :label="$t('shop.product.stock')"
      />
      <hcc-textarea
        name="description"
        :placeholder="$t('shop.product.description')"
        v-model="newProduct.descripcion"
        :label="$t('shop.product.description')"
      />
      <div class="shop__container" v-if="!isProduct">
        <div class="shop__group">
        <span>{{ $t("shop.product.status") }}</span>
        <div class="shop__status">
          <hcc-toggle-switch
            name="toggle-status"
            :value="newProduct.status"
            @change="toggleStatus"
          />
        </div>
        </div>
      </div>
    </div>
    <div class="cell small-12 medium-6">
      <div class="multimedia__drop">
        <vue-file-agent
          ref="dropZone"
          :multiple="false"
          :helpText="$t('shop.product.image')"
          :maxFiles="1"
          :meta="true"
          :compact="true"
          theme="grid"
          accept="image/*"
          :maxSize="maxSize"
          @select="selectImage"
          v-model="imageProduct"
        >
        </vue-file-agent>
      </div>
    </div>
    <div class="cell flex-container align-right save-buttons">
      <router-link
        :to="`/store/${$route.params.idStore}/edit`"
      >
        <hcc-button
          :size="buttonSize"
          variant="outline"
          color="muted"
        >
          <span >
            {{ $t("shop.cancel") }}
          </span>
        </hcc-button>
      </router-link>
      <hcc-button
        :disabled="!validForm"
        :size="buttonSize"
        @click="updateProduct()"
      >
        <span >
          {{ $t("shop.save") }}
        </span>
      </hcc-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    HccInput: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccInput/index.vue'),
    HccMoney: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccMoney/index.vue'),
    HccTextarea: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccTextarea/index.vue'),
    HccSelect: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccSelect/index.vue'),
    HccToggleSwitch: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccToggleSwitch/index.vue'),
    HccButton: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccButton/index.vue'),
  },
  props: {
    product: {
      require: true,
    },
    idProduct: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      reload: false,
      newProduct: {},
      imageProduct: {},
    };
  },
  validations: {
    newProduct: {
      nombre: { required },
      descripcion: { required },
      precio: { required },
      precioCompra: { required },
      categoria: { required },
    },
  },
  computed: {
    ...mapState({
      myStore: state => state.shop.item,
      idProductState: state => state.product.idProduct,
    }),
    validForm() {
      return !this.$v.$invalid;
    },
    maxSize() {
      return process.env.VUE_APP_UPLOAD_MAX_FILESIZE;
    },
    categories() {
      return [
        { id: 'Producto', value: this.$t('shop.product.title') },
        { id: 'Servicio', value: this.$t('shop.service') },
      ];
    },
    isProduct() {
      return this.newProduct.categoria?.id === 'Producto';
    },
    buttonSize() {
      return this.windowWidth <= 1125 ? 'xs' : 'sm';
    },
  },
  watch: {
    product(newValue) {
      this.newProduct = newValue;
    },
    'newProduct.image': function watchImage(newValue) {
      if (newValue.url) {
        this.imageProduct = newValue;
        this.newProduct.image = newValue;
      }
    },
  },
  methods: {
    ...mapActions({
      getProductById: 'product/getById',
      createProduct: 'product/save',
    }),
    async updateProduct() {
      const dataProduct = {
        ...this.newProduct,
        image: this.imageProduct,
        storeId: this.$route.params.idStore,
        id: this.idProduct,
      };
      delete dataProduct?.image;
      await this.createProduct(dataProduct);
      this.$toasted.global.success(this.$t('shop.product.options.edit-success-text'));
      if (this.idProductState !== this.$route.params.idProduct) {
        this.$router.push({
          path: `/store/${this.$route.params.idStore}/product/${this.idProductState}`,
        });
      }
    },
    focusBuildModal() {
      this.$refs.hccInput.focus();
    },
    selectImage(files) {
      const { file } = files[0];
      this.newProduct.file = file;
    },
    toggleStatus() {
      this.newProduct.status = !this.newProduct.status;
    },
  },
};
</script>
<style scoped lang="scss">
@import "~styles/views/_shop.scss";
</style>
